/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

* {
	font-family: 'Inter', sans-serif;
}

.expanded span.brand-logo {
	/* background: red; */
	transition: 0.2s;
	translate: 100%;
	scale: 2;
}

.menu-open span.brand-logo {
	/* background: red; */
	translate: 100%;
	scale: 2;
	transition: 0.2s;
}

.eat-btn {
	background: linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%);
	color: #FFFFFF;
	border: #F2473F;
	border-radius: 5px;
	border: 'none';
}

.eat-btn-success,
.eat-btn-success:focus {
	background: #01A879 !important;
	color: #FFFFFF;
	border: #01A879;
	border-radius: 5px;
	border: 'none';
}

.eat-btn-success:hover {
	box-shadow: 0 8px 25px -8px #01A879 !important;
}

.eat-btn-2,
.eat-btn-2:focus {
	background-color: #702913 !important;
	color: #FFFFFF;
	border-color: #702913;
	border-radius: 5px;
}

.eat-btn-2:hover {
	box-shadow: 0 8px 25px -8px #702913 !important;
}

.eat-label-1 {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.125rem;
	color: #A1B3C9;
}

.eat-status {
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.188rem;

	color: #000000;
	align-items: center;
}

.eat-btn-sweetalert {
	background: #01A879;
	color: #FFFFFF;
	border: #01A879;
	border-radius: 5px;
	height: 3em;
	width: 4em;
}

.title-sweetalert {
	color: #01A879 !important;
}

.eat-btn:hover {
	box-shadow: #F2473F !important;
}

.custom-avtar .upload-image {
	display: none;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	// background: #E7ECF0;
	background: #00000042;
	width: 100%;
	height: 100%;
	gap: 0.35rem;
	z-index: 10;
}

.custom-avtar:hover .upload-image {
	display: flex;
}

.upload-image p {
	font-weight: 500;
	font-size: 0.625rem;
	line-height: 1.5rem;
	color: #768DA9;
}

.tooltip-inner {
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(181, 181, 181, 0.25);
	// transform: matrix(1, 0, 0, -1, 0, 0);
}

.tooltip-arrow::before {
	border-top-color: #fcf9f9 !important;
}

.tooltip-inner ul {
	list-style: none;
	text-align: left;
}

.icon-hover:hover {
	cursor: pointer;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
[dir] .btn-outline-secondary:not(:disabled):not(.disabled):focus {
	background: linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%);
	border: 1px solid transparent;
	color: white !important;
}

.sat-card {
	min-width: '205px';
	// height: '100px';
	overflow: hidden
}

.form-check-input:checked {
	background-color: #F2473F;
	border-color: #F2473F;
}

// .form-label{
// 	padding-left: 0 !important;
// }

.dropdown-toggle::after {
	content: none;
}


// Styling table
.table-bottom {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center
}

.table-bottom ul {
	margin: 0;
}

.sc-dmRaPn {
	height: 100% !important;
	background: #ffffff;
}

.sc-dmRaPn::-webkit-scrollbar {
	width: 0;
}

// .css-m2o6ej-multiValue {
//     background-color: rgba(0, 0, 0, 0.12);
//     border-radius: 12px;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     margin: 2px;
//     min-width: 0;
//     max-width: fit-content;
//     box-sizing: border-box;
// }
// .css-1rhbuit-multiValue,
// .css-g1d714-ValueContainer div,
// .css-k3ockt-control div{
// width: auto !important;
// border-radius: 10px;
// max-width: max-content;
// }
.css-qc6sy-singleValue {
	color: #F2473F;
}

.css-1okebmr-indicatorSeparator {
	display: none;
}

// .css-1s2u09g-control,
// .css-1pahdxg-control,
// .css-1s2u09g-control:focus{
// border-color: #F2473F !important;
// color: #F2473F;
// fill: #F2473F;
// }


.css-1rhbuit-multiValue,
.css-g1d714-ValueContainer div,
.css-k3ockt-control div {
	width: auto !important;
	border-radius: 10px;
	max-width: max-content;
}

.color-danger path,
.color-danger .css-14el2xx-placeholder,
.color-danger .css-1s2u09g-control {
	border-color: #F2473F !important;
	color: #F2473F;
	fill: #F2473F;
}

.dropdown-menu[data-popper-placement]:not([data-popper-pla]) {
	width: 100%;
}

.dropdown-menu .dropdown-item {
	width: 100%;
}

.eatslogo {
	width: 56.89px;
	height: 19px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 25.1305px;
	line-height: 30px;

	background: linear-gradient(180.61deg, #5E3225 7.84%, #832A0E 91.39%);
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
	background-clip: text !important;
	// -webkit-text-stroke-color: transparent;
	// text-fill-color: transparent !important;
}

.strong {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 21px;
	color: #292D32;
}

.soft {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: #A1ACBA;
}

.no-wrap {
	white-space: nowrap;
}

// ================ Data Table====================
// .rdt_Table {
// 	min-height: 200px !important;
// }

.rdt_TableHeadRow div {
	// text-overflow:clip;
	white-space: break-spaces;
	// text-align: left;
	// padding: 0;
	// background: red !important;
}

// .rdt_TableCell {
// 	min-width: max-content !important;
// }
// .rdt_TableCell div{
// 	min-width: max-content !important;
// 	// margin: 0 1rem;
// }
// // .rdt_TableCell div{
// // 	min-width: fit-content !important;
// // 	// margin: 0 1rem;
// // }
.rdt_TableCol {
	gap: 8rem;
}

// .rdt_Table{
// 	background-color: red;
// 	width: 300% !important;
// 	max-width: max-content !important;
// }
.qr-check {
	height: 1rem;
	width: 1rem;
}

.qr-check:indeterminate,
.qr-check:checked {
	background-color: brown !important;
	box-shadow: none !important;
	border: none;
	appearance: none;
	height: 1rem;
	width: 1rem;
	background-size: 55%;
	// background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus'%3e%3cline x1='5' y1='12' x2='19' y2='12'%3e%3c/line%3e%3c/svg%3e");
}

// .qr-check:checked{
// 	background-image: ;
// }
.prev,
.next {
	cursor: pointer
}

.stat {
	display: flex !important;
	justify-content: space-between !important;
}

.success-btn {
	width: fit-content;
	margin: 10px auto;
}

.import-export-dropdown {
	color: #01A879 !important;
	border: 1px solid #01A879 !important;
	background-color: transparent;
}

.input-pdf:hover {
	box-shadow: 0px 0px 1px 1px #F2473F;
}

.input-pdf div:active {
	background-color: rgba(128, 128, 128, 0.142);
}

.input-group-text:active {
	background-color: rgba(128, 128, 128, 0.079);
	box-shadow: 0px 0px 1px 1px green;
}

.search-input {

	position: relative;
	width: 300px;
	margin-right: auto;
	// margin-bottom: 10px;
}

.search-input input {
	padding-left: 30px;
}

.search-icon svg {
	display: block;
	position: absolute;
	// border: 2px solid blue;
	top: 0;
	opacity: .7;
	margin-left: 5px;
	margin-top: 7px;
	scale: .8;
}

.refreshing {
	cursor: pointer;
	animation: rot 1s linear infinite forwards;
}

.import-model-wrapper {
	// width: 300px;
	position: absolute;
	bottom: 0;
	right: 0;
	margin-bottom: 13vh;
	margin-right: 2vw;
	box-sizing: border-box;
	width: 388px;
	height: fit-content;
	background: #FFFFFF;
	border: 1px solid #FFEEE8;
	// box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	overflow: hidden;
	/* Auto layout */


}

.import-model-mainarea {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px 20px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	// line-height: 160%;
	text-align: center;
	color: #616161;
}

.import-model-wrapper h2 {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #002B51;

}

.import-model-head {

	display: flex;
	width: 100%;
	color: #002B51;
	background: #FFFFFF;
	padding: 10px 20px 5px 10px;
	justify-content: space-between;
	flex-direction: row;
	border-bottom: 1px solid #EEEFF0;
	;
}

.import-model-head i {
	border: .1rem solid rgba(165, 152, 152, 0.563);
	padding: 1.5px;
	border-radius: 50%;
	color: #97AAC2;
	cursor: pointer;
	height: fit-content;
}

.import-model-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 5px;
	text-align: center;
}

.import-model-dragarea {
	overflow: hidden;
	width: 100%;
	outline: 7px dashed white;
	outline-offset: -3px;
	border: 2px solid #702913;
	height: 203px;
	padding: 20px 0;
	border-radius: 16px;
	color: black;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
}

.import-model-body2 {
	width: 100%;
	margin-top: 10px;
}

.importing-exporting {
	display: flex;
	width: 94%;
	justify-content: space-between;
	align-items: center;
	margin: 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
}

.importing-exporting p {
	margin: 0 0 5px 0;
	text-align: start;
}

.import-model-progressbar {
	width: 70%;
}

.export-file-logo {
	position: absolute;
	right: 0;
	z-index: 1400;
	bottom: -72vh;
	cursor: pointer;
}

.odrinv-wrap {
	width: 300px;
	// display:flex;
	// align-items: center;
	// flex-direction: column;
}

.odrinv-head {
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
}

.odrinv-total {
	margin: 20px 10px 30px 10px;
}

.odrinv-total div {
	width: 100%;
	display: flex;
	justify-content: space-between;
	// border:2px solid blue
}

.odrinv-foot {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cafe-profile-sm {
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: #1A264E;
}

.datatable-title-head {
	font-weight: 700;
	font-size: 22px;
	line-height: 21px;
	color: #1A264E;
	padding: 0;
	margin: 0;
}

.cafe-profile-lg {
	font-weight: 700;
	font-size: 16px;
	line-height: 17px;
	color: #1A264E;
}

.cafe-profile-imghead {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #5E5873;
}

.css-4ljt47-MenuList,
.css-yt9ioa-option,
.css-yt9ioa-option {
	color: #1A264E !important;
}

.css-1pahdxg-control {
	border-color: #F2473F !important;
	box-shadow: 0 0 0 0px #F2473F !important;
}

::-webkit-scrollbar {
	width: 0.5em;
	height: 1rem
}

::-webkit-scrollbar-track {
	background: rgb(240, 239, 239);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: rgba(165, 161, 161, 0.479);
	border-radius: 50px;

}

::-webkit-scrollbar-thumb:hover {
	background: #ff6a348a;
}


@media (width < 950px) {
	.stat {
		display: block !important;
		justify-content: space-between !important;
	}

	.stat div {
		margin-bottom: 10px !important;
	}
}


@keyframes rot {
	from {
		rotate: (0deg);
	}

	to {
		rotate: (360deg);
	}
}

.truncate {
	width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

header {
	padding: 0;
}

.eUeqdG {
	white-space: pre-line;
}

// .bLyqNT,
// .jfrgbA{
//   justify-content: center !important;
// }


::-webkit-scrollbar {
	width: 0.5em;
	height: 0.5rem
}

::-webkit-scrollbar-track {
	background: rgb(240, 239, 239);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: rgba(165, 161, 161, 0.479);
	border-radius: 50px;

}

::-webkit-scrollbar-thumb:hover {
	background: #ff6a348a;
}


.icon-btn:hover {
	scale: 1.001;
}

.icon-btn:active {
	scale: 1;
}


//--------------------------------------
.version {
	display: none;
}

.expanded .version {
	display: contents;
}

.links-title {
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 0.9375rem;
	color: #5E5873;
	width: 8rem;
}

.links {
	font-style: normal;
	font-weight: 600;
	font-size: 0.75rem;
	line-height: 1.5rem;

	color: #28385F;

	flex: none;
	order: 1;
	flex-grow: 0;
	word-break: break-all;
}

.header-1 {
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.8125rem;
}

.pill-active,
.pill {
	background: white;
	color: #B9B9C3;
	border: 0.5px solid #B9B9C3;
	padding: 0.2rem 1rem;
	border-radius: 20px;
	width: max-content;
	margin: 2px;
	cursor: pointer;
}

.pill-active {
	background: linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%);
	color: white;
	border: 0.5px solid #F2473F;
}

.form-switch input {
	cursor: pointer !important;
}


/// table fix

@media  (width>750px) {
	.custum-table-fix .rdt_TableHeadRow .rdt_TableCol:last-child {
		position: sticky;
		right: 0;
		background: inherit;
		z-index: 0;
	}
	
	.custum-table-fix .rdt_TableCell:last-child {
		position: sticky;
		right: 0;
		background: inherit;
		z-index: 0;
	}
	
	.custum-table-fix .rdt_TableRow:hover .rdt_TableCell:last-child {
		color: rgba(0, 0, 0, 0.87);
		background-color: inherit;
		// -webkit-transition-duration: 0.15s;
		// transition-duration: 0.15s;
		-webkit-transition-property: background-color;
		transition-property: background-color;
		border-bottom-color: #FFFFFF;
		outline-style: solid;
		outline-width: 1px;
		outline-color: #FFFFFF;
	}
	
	.custum-table-fix .rdt_TableCell:last-child:has(.show) {
		z-index: 10;
	}
	
	.custum-table-fix .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2) {
		position: sticky;
		right: 100px;
		background: inherit;
	}
	
	.custum-table-fix .rdt_TableCell:nth-last-child(2) {
		position: sticky;
		right: 100px;
		background: inherit;
	}
	
	.custum-table-fix .rdt_TableRow:hover .rdt_TableCell:nth-last-child(2) {
		color: rgba(0, 0, 0, 0.87);
		background-color: inherit;
		// -webkit-transition-duration: 0.15s;
		// transition-duration: 0.15s;
		-webkit-transition-property: background-color;
		transition-property: background-color;
		border-bottom-color: #FFFFFF;
		outline-style: solid;
		outline-width: 1px;
		outline-color: #FFFFFF;
	}
}


// ==============================================


.recipt {
	* {
		font-family: 'Fira Code', monospace !important;
		color: #000000 !important;
	}

	p {
		margin: 0;
	}

	table {

		td,
		th {
			padding: 0rem !important;
		}

	}

	.soft {
		color: #000000;
	}

	.odrinv-wrap {
		width: fit-content;
		max-width: 340px;
	}

	.odrinv-head {
		display: flex;
		align-items: center;
		width: 100%;
		flex-direction: column;
	}

	.odrinv-total div {
		width: 100%;
		display: flex;
		justify-content: space-between;
		// border:2px solid blue
	}

	.odrinv-foot {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

#invoice .soft {
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 0.9375rem;
	letter-spacing: 0px;
	// color: #405076;
}

#invoice .strong {
	font-size: 0.625rem;
	font-weight: 600;
	line-height: 1.3125rem;
	letter-spacing: 0px;
	text-align: left;
	// color: #1A264E;

}


#invoice .midfont {
	font-size: 1rem;
	font-weight: 500;
	line-height: 1rem;
	letter-spacing: 0px;
	text-align: right;

}

@media print {

	body * {
		visibility: hidden;
	}

	#freshworks-container {
		display: none;
	}

	#SvgjsSvg1001 {
		display: none;
	}

	#invoice,
	#invoice * {
		visibility: visible;
	}

	#invoice {
		visibility: visible;
		width: 100%;
		position: fixed;
		top: 0;
		display: flex;
		justify-content: center;
		top: 0
	}

}